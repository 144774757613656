<template>
  <div class="p-2 flex items-center text-primary group-hover:text-secondary" @click="isShowMobileSearch=!isShowMobileSearch">
    <LucideSearch class="size-5 sm:size-4" v-if="!isShowMobileSearch" />
    <LucideX class="size-5 sm:size-4" v-else />
  </div>
</template>

<script setup>
const {isShowMobileSearch} = useHelper()
</script>

